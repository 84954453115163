<template>
  <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
    <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('connectedPage.listOfProject') }}</span>
    <div class="fd-flex fd-items-center fd-justify-between">
      <span
        class="fd-text-sm fd-font-medium fd-text-theme-2"
      >
        {{ $t('connectedPage.totalProject') + ` ${total}` }}
      </span>

      <div class="ml-auto fd-flex fd-items-center fd-my-6 xl:fd-my-0 fd-mr-0">
        <span class="fd-text-sm fd-mr-4" style="color: #6E6B7B">{{ $t('global.search') }}</span>
        <b-form-input v-model="search" type="text" debounce="500" class="fd-w-1/2"></b-form-input>
      </div>
    </div>

    <div class="fd-w-full fd-grid fd-gap-4 fd-grid-cols-4 fd-mt-8">
      <div
        v-for="project in projects"
        :key="project.id"
        class="rs-project-card"
      >
        <div class="fd-flex fd-items-center fd-justify-between">
          <span class="fd-font-bold fd-text-sm fd-text-theme-6">{{ project.name }}</span>
          <Actions
            :verticalIcon="false"
            :hasRemoveIcon="false"
            :hasViewIcon="false"
            @showEditComponent="editProject(project.id)"
          />
        </div>
        <div class="image-section">
          <img :src="project.thumbnail" :alt="project.name">
        </div>

        <div class="fd-flex fd-items-center fd-justify-between">
          <span class="fd-font-medium fd-text-xs fd-text-theme-6">{{ $t('connectedPage.salesAmount') }}</span>
          <span class="fd-font-medium fd-text-xs fd-text-theme-6">{{ project.sold_amount.dollar }}</span>
        </div>
        <div class="fd-flex fd-items-center fd-justify-between fd-my-2">
          <span class="fd-font-medium fd-text-xs fd-text-theme-6">{{ $t('connectedPage.salesNumber') }}</span>
          <span class="fd-font-medium fd-text-xs fd-text-theme-6">{{ project.sold_count }}</span>
        </div>
        <div class="fd-flex fd-items-center fd-justify-between">
          <span class="fd-font-medium fd-text-xs fd-text-theme-6">{{ $t('connectedPage.commission') }}</span>
          <span class="fd-font-medium fd-text-xs fd-text-theme-6">{{ project.commission_rate }}</span>
        </div>
      </div>
    </div>

    <div>
      <b-sidebar
        id="edit-project-sidebar"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <div>
            <div class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
              <div class="fd-flex fd-items-center">
                <span class="fd-block fd-ml-2">{{ $t('global.edit') }}</span>
              </div>
              <div @click="closeSidebar" class="fd-cursor-pointer">
                <SvgLoader :name="'close'" />
              </div>
            </div>
            <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3 fd-mx-3 fd-mt-4">
              <label for="id" class="fd-mb-2">{{ $t('connectedPage.commissionFee') }}</label>
              <b-form-input id="id" v-model="form.commission_rate" :placeholder="'% ' + $t('connectedPage.commissionFee')" />
            </div>
            <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-absolute fd-left-0 fd-bottom-0 fd-pl-3 fd-pb-3 fd-pr-1">
              <b-button @click="update" block variant="primary" class="fd-mr-2">{{ $t('global.save') }}</b-button>
            </div>
          </div>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import {mapGetters} from "vuex";
import toast from "@/mixins/toast";
import { BFormInput, BSidebar, BButton } from 'bootstrap-vue'
import Actions from "@/views/components/global/Actions";

export default {
  name: "Projects",
  components: {Actions, Table, BFormInput, BSidebar, BButton},
  mixins: [toast],
  data() {
    return {
      loading: false,
      btnTitle: null,
      search: '',
      projectSelected: null,
      form: {
        commission_rate: null
      }
    }
  },
  created() {
    let data = {
      id: this.$route.params.id
    }
    this.getData(data)
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      projects: 'rs/rsProjects',
      total: 'rs/rsProjectsTotal'
    }),
    fields() {
      return [
        { key: 'name', label: this.$t('table.name'), sortable: true, sortDirection: 'desc' },
        { key: 'commission_rate', label: this.$t('table.commissionRate') },
        { key: 'property', label: this.$t('table.property') },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right' }
      ]
    },
    name() {
      return this.$t('connectedPage.projects')
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true

      this.$store.dispatch('rs/getRsProjects', data).finally(() => {
        this.loading = false
      })
    },
    setFilters(filters) {
      console.log(filters)
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    editProject(id) {
      this.projectSelected = id
      this.projects.forEach(item => {
        this.form.commission_rate = item.commission_rate
      })
      this.$root.$emit('bv::toggle::collapse', 'edit-project-sidebar')
    },
    closeSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'edit-project-sidebar')
    },
    update() {
      this.$store.dispatch('rs/updateCommissionRate', {
        id: this.$route.params.id,
        projectSelected: this.projectSelected,
        data: this.form
      })
        .then(() => {
          this.closeSidebar()
          let data = {
            id: this.$route.params.id
          }
          this.getData(data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rs-project-card {
  box-shadow: 0 4px 24px rgba(40, 74, 51, 0.06);
  border-radius: 6px;
  padding: 8.25px 10px;

  .image-section {
    width: 215px;
    height: 125px;
    overflow: hidden;
    border-radius: 6px;
    margin: 8px 0;

    img {
      width: 215px;
      height: 125px;
      object-fit: cover;
    }
  }
}
</style>